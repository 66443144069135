import React from "react";

const PrivacyPolicyPage = () => (
  <>
    <h1>Privacy Policy</h1>
    <p>Privacy policy text.</p>
    <a href="/">Torna alla home</a>
  </>
);

export default PrivacyPolicyPage;
